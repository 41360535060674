// LogoutAPI.js
import API from "../../api";
import ENDPOINTS from "../../../../../config/apiEndpoint";
import C from "../../../../constants";

export default class FetchNotificationsReadAPI extends API {
  constructor( id, timeout = 2000) {
    super("PUT", timeout, false); 
    this.type = C.FETCH_NOTIFICATIONS_READ;
    this.endpoint = `${super.apiEndPointAuto()}${ENDPOINTS.notification_read}/${id}`;
  }

  processResponse(res) {
    super.processResponse(res);
    if (res) {
        this.report = res;
    }
  }

  apiEndPoint() {
    return this.endpoint;
  }

  getBody() { }  

  getHeaders() {
    return {
      headers: {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('token')
      },
    };
  }

  getPayload() {
    return this.report;
  }
}
