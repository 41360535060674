import React, { useState } from "react";
import {
  Drawer,
  Button,
  IconButton,
  Typography,
  Box,
  Divider,
  Avatar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { APITransport } from "../../redux/actions";
import { useEffect } from "react";
import FetchNotificationsReadAPI from "../../redux/actions/api/Dashboard/FetchNotifications/NotificationRead";
import { getFormattedDate } from "../../utils/utils";
import FetchNotificationsAPI from "../../redux/actions/api/Dashboard/FetchNotifications/NotificationsList";

const Notifications = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const email = localStorage.getItem("email");
  const apiStatus = useSelector((state) => state.apiStatus)

  const Notificationlist = useSelector(
    (state) => state?.fetchNotifications.data
  );

  useEffect(() => {
    if (apiStatus && apiStatus.apiType === "FETCH_NOTIFICATIONS_READ" && apiStatus.success) {
      const playload = { to_email_id: email, is_read: false };
      const apiObj = new FetchNotificationsAPI(playload);
      dispatch(APITransport(apiObj));
    }
}, [apiStatus,dispatch])

  const markAsRead = (id) => {
    const apiObj = new FetchNotificationsReadAPI(id);
    dispatch(APITransport(apiObj));
  };


  return (
    <Drawer
  anchor="right"
  open={open}
  onClose={onClose}
  sx={{
    "& .MuiDrawer-paper": {
      width: "400px",
      padding: "10px 2px 85px 16px",
      marginTop: "84px",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
    },
  }}
>
  <IconButton
    onClick={onClose}
    sx={{ position: "absolute", top: 8, right: 8 }}
  >
    <CloseIcon />
  </IconButton>
  <Typography
    variant="h6"
    gutterBottom
    sx={{
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "18px",
    }}
  >
    Notification
  </Typography>
  <Divider sx={{ margin: "0px 0px 15px 0px" }} />
  <Box
    sx={{
      flex: 1, 
      overflowY: "auto",
    }}
  >
    {Notificationlist.requests?.map((item, index) => (
      <React.Fragment key={index}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ mr: 2 }}></Avatar>
          <Box>
            <Typography
              variant="body2"
              sx={{
                fontFamily: "Inter, sans-serif",
                fontSize: "14px",
                fontWeight: 400,
                color:item?.is_read
                  ? "#a6acaf"
                  : "black",
              }}
            >
              {item.notification_body}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontFamily: "Inter, sans-serif",
                fontSize: "14px",
                fontWeight: 400,
                mt: 1,
                color:item?.is_read
                  ? "#a6acaf"
                  : "gray",
              }}
            >
             {getFormattedDate(item.created_on)}
            </Typography>
          </Box>
        </Box>
        <Button
          variant="text"
          sx={{
            marginLeft: "auto",
            display: "block",
            textDecoration: "underline",
            fontSize: "14px",
          }}
          onClick={() => markAsRead(item._id)}
        >
          {" "}
          Mark as read
        </Button>

       
          <Divider sx={{ mb: 2, borderColor: "#f2f3f4 " }} />
      </React.Fragment>
    ))}
  </Box>
</Drawer>

  );
};

export default Notifications;
