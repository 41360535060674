const apiEndpoint = {
    login: '/v1/login',
    resetPassword: '/v1/reset_password',
    forgotResetPassword: '/v1/forgot-password-complete',
    forgotPassword: '/v1/forgot-password',
    fetchOnboardUsers: "/v1/fetch-onboard-users",
    updateOnboardUser : '/v1/update-onboard-user',
    fetchOnboardManagers : '/v1/fetch-onboard-managers',
    updateOnboardManagers : '/v1/assign-onboard-users',
    userProfileSubmit: '/v1/user_profile_update',
    editUserProfileSubmit: '/v1/edit_profile_details',
    organisationTypes: '/v1/list_org_type',
    fetchReferenceApplicationStatus: '/v1/list_applications',
    fetchLoggedInUserDetails: '/v1/get-user-details',
    fetchReferenceApplication: '/v1/request_applications',
    fetchPluginUlcaKeys: '/v1/user_ulca_keys',
    fetchPluginUlcaKeysdata: '/v1/list_translation_plugin_requests',
    fetchAppNumbers: '/v1/my_applications_aggr',
    resubmitApplication: '/v1/resubmit_application_access',
    referenceApplicationRequest: '/v1/request_application_access',
    addPluginData: '/v1/request_transln_plugin',
    editPluginData: '/v1/update_transln_plugin_request',
    deletePluginData: '/v1/delete_transln_plugin',
    revokeReferenceApplicationRequest: '/v1/revoke_request',
    getApplicationDetails: '/v1/get_app_request_details',
    getBasicUserDeatils :'/basic-user-details',
    getAdvanceUserDeatils :'/advance-user-details',
    fetchOnboardingRequests:'/v1/list_app_requests',
    fetchSahyogiRequests: '/v1/fetch_technology_form',
    SahyogiDownloadFile: '/v1/sahyogi/proof/download',
    updateOnboardingRequest: '/v1/update_app_request',
    logout: '/v1/logout',
    FetchCeocustomer_overview :'/v1/customer_overview',
    FetchCeodashbord :'/v1/app_requests_status_aggr',
    FetchCeodashbordManager:'/v1/app_requests_status_aggr_manager',
    getAppRequestDetails: '/v1/get_app_request_details',
  
    getAppRequestDetails: '/v1/get_app_request_details',
    FetchCeodashbord :'/v1/customer_overview',
    FetchCeodashbord :'/v1/app_requests_status_aggr',

    getAppRequestDetails: '/v1/get_app_request_details',
    getManagerRequests: '/v1/registered-manager-requests',
    updateManagerRegistration: '/v1/update_manager_reg_request',

    FetchCeoSahyogiRequest:'/v1/sahyogi/all_user_details',
   SingleUserDetail:'/v1/sahyogi/single_user_details',
   managerrequest:'/v1/manager_request_stats',
   SahyogiRequestOverview:'/v1/sahyogi/dashboard',
   EmployeeInviteManager:"/v1/invited_manager_request_stats",
   InviteManager:"/v1/invite_manager",
   RevokeManager:"/v1/revoke_manager",
   getUserDetails: '/v1/get-user-details',
   getCheckListDetails: '/v1/list_parikshan_mgr_checklist',
   userProfileUpdate: '/v1/edit_profile_details',
   getProofDownloadUrl: '/v1/proof/download',
   viewPDF:'/v1/sahyogi/proof/view',
   verifyManagerRegistration:'/v1/verify_manager_registration',
   translationPluginRequests:'/v1/list_translation_plugin_requests',
   translationPluginRequestsdetails:'/v1/get_transln_plugin_request_details',
   translationPluginStstus:"/v1/update_transln_plugin_status",
   listUsers: "/v1/list_users",
   customerCompleteDetails:"/v1/customer_complete_details",
   managerList:"/v1/registered-manager-requests",
   managersOverView:"/v1/reference_app_aggr_counts_manager",
   ApplicationRequestStatus:"/v1/reference_app_aggr_counts",
   registeredExpertList:"/v1/registered-linguistics-requests",
   registeredExpertdetails: "/v1/registered-linguistics-request-details",
   list_languages:"/v1/list_languages",
   list_notifications:"/v1/list_notifications",
   notification_read:"/v1/notification_read",
   revert_to_manager:"/v1/revert_to_manager",
}

export default apiEndpoint;

