import { Box, Button, Container, Divider, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { convertTimestamp, getFormattedDate, getFormattedDateandTime } from '../../utils/utils';
import ResubmitApplicationApi from '../../redux/actions/api/ReferenceApplications/ResubmitApplication';
import { APITransport } from '../../redux/actions';
import { setSnackBar } from "../../redux/actions/Common";
import ViewWTMSDetails from './ViewWTMSDetails';
import FetchPluginUlcaKeysApi from '../../redux/actions/api/ReferenceApplications/FetchPluginUlcaKeys';
import RequestdetailsStepper from "./RequestdetailsStepper";
const ViewApplicationDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const myapplicationDetail = useSelector((state) => state.fetchReferenceApplicationDetails?.data?.app_details?.[0]);
    const UlcaApiKeys = useSelector((state) => state.fetchPluginUlcaKeys?.data);
    
    const email = localStorage.getItem("email");
    const apiStatus = useSelector((state) => state.apiStatus);
    // const [isApiKeyAvailable, setIsApiKeyAvailable] = useState(false);

    // Convert the requested_on timestamp
    const { dateWithTimezone, timeOnly } = getFormattedDateandTime(myapplicationDetail?.requested_on);

    // Helper function to get formatted approval date and time
    const getApprovalInfo = (approval) => {
        if (!approval?.updated_on) return { dateWithTimezone: '', timeOnly: '' };
        const { dateWithTimezone, timeOnly } = getFormattedDateandTime(approval.updated_on);
        return { dateWithTimezone, timeOnly };
    };

    const ResubmitApplication = (id) => {
        const reqObj = new ResubmitApplicationApi(id);
        dispatch(APITransport(reqObj));
      };

      useEffect(() => {
        const handleSuccess = (message) => {
            dispatch(setSnackBar({
                open: true,
                message: message,
                variant: "success"
            }));
        };
    
        const handleError = (errorMessage) => {
            dispatch(setSnackBar({
                open: true,
                message: errorMessage,
                variant: "error"
            }));
        };

        if (apiStatus?.success) {
            if (apiStatus.apiType === "RESUBMIT_APPLICATION") {
                handleSuccess("Application Resubmitted Successfully");
                navigate('/applications');
            }
            // } else if (apiStatus.apiType === "FETCH_PLUGIN_ULCA_KEYS") {
            //     handleSuccess("ULCA Keys fetched Successfully");
            // }
        }
    
        if (apiStatus.apiType === "FETCH_PLUGIN_ULCA_KEYS" && myapplicationDetail?.application_name === 'Bhashini Translation Plugin'  && apiStatus?.errors) {
            handleError(apiStatus?.data?.detail);
        }
        // if (apiStatus.apiType === "RESUBMIT_APPLICATION") {
        //     apiStatus?.success && 
        //     navigate('/applications');
        //   dispatch(setSnackBar({
        //     open: true,
        //     message: "Application Resubmmitted Successfully",
        //     variant: "success"
        //   }));
        // }
        // if (apiStatus.apiType === "FETCH_PLUGIN_ULCA_KEYS") {
        //     apiStatus?.success && 
        //     navigate('/applications');
        //   dispatch(setSnackBar({
        //     open: true,
        //     message: "Application Resubmmitted Successfully",
        //     variant: "success"
        //   }));
        //   apiStatus?.errors && 
        //   dispatch(setSnackBar({
        //     open: true,
        //     message: apiStatus?.data?.detail,
        //     variant: "error"
        //   }));
          
        // }
        
      }, [apiStatus]);

    // Get approval info for CEO and Manager
    const ceoApprovalInfo = getApprovalInfo(myapplicationDetail?.ceo_approval);
    const managerApprovalInfo = getApprovalInfo(myapplicationDetail?.manager_approval);

    // Helper function to determine label based on status
    const getApprovalLabel = (status) => {
        if (status === 'Approved') {
            return { dateLabel: 'Approved Date', timeLabel: 'Approved Time' };
        } else if (status === 'Rejected') {
            return { dateLabel: 'Rejected Date', timeLabel: 'Rejected Time' };
        }
        return { dateLabel: '', timeLabel: '' };
    };
    const isMounted = useRef(false);
    // Determine labels for CEO and Manager approvals
    const ceoLabels = getApprovalLabel(myapplicationDetail?.ceo_approval?.status);
    const managerLabels = getApprovalLabel(myapplicationDetail?.manager_approval?.status);

    // Determine if rejection section should be displayed
    const showRejectionSection = myapplicationDetail?.status === 'Rejected';
    const showUserManual = (myapplicationDetail?.application_name === 'Bhashini Translation Plugin' && myapplicationDetail?.status === 'Approved')
 
      const FetchingPluginUlcaKeys = () => {
        const reqObj = new FetchPluginUlcaKeysApi(email);
        dispatch(APITransport(reqObj));
      };
    
      useEffect(() => {
        if (isMounted.current) {
            const timeoutId = setTimeout(() => {
                if (myapplicationDetail?.application_name === 'Bhashini Translation Plugin' && myapplicationDetail?.status === 'Approved') {
                    FetchingPluginUlcaKeys();
                }
            }, 1000);  
            return () => clearTimeout(timeoutId);
          } else {
            isMounted.current = true;
          }
      }, [myapplicationDetail?.application_name,myapplicationDetail?.status]);
      
        const handleClickUserManual = () => {
          window.open('https://userdatav1.blob.core.windows.net/dashboardblob/Bhashini_Translation_Plugin_Documentation_v2.pdf', '_blank', 'noopener,noreferrer');
        };

    return (
        <Container style={{ maxWidth: "100%" }}>
            <Paper elevation={3} sx={{ p: 3, mt: 4 }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Button variant="text" color='inherit' onClick={() => navigate(-1, { replace: true })}>
                        <ArrowBackOutlinedIcon /> &nbsp; Application request : {myapplicationDetail?.application_name}
                    </Button>
                </Box>
                <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
                    <Box display="flex" justifyContent="center" alignItems="center" gap="20px">
                        <Box>
                            <Typography fontFamily={"Inter, sans-serif"} variant="h3" fontWeight={"600"} gutterBottom sx={{ mt: 2 }}>
                                {myapplicationDetail?.application_name}
                            </Typography>
                            {/* <Typography fontFamily={"Inter, sans-serif"} variant="body2" fontWeight={"400"} gutterBottom>
                                Web Translation Plugin
                            </Typography> */}
                        </Box>
                        <Box style={{ borderRadius: "40px", backgroundColor: myapplicationDetail?.status === "Approved" ? "#DEFFDB" : "#FFF1F0", padding: "12px 30px 6px 30px" }}>
                            <Typography fontFamily={"Inter, sans-serif"} color={myapplicationDetail?.status === "Approved" ? '#0C8501' :"#E02B1D" }variant="body2" fontWeight={"600"} gutterBottom>
                                {myapplicationDetail?.status}
                            </Typography>
                        </Box>
                    </Box>
                    <RequestdetailsStepper myapplicationDetail={myapplicationDetail} showRejectionSection={showRejectionSection} showUserManual={showUserManual}/>

                    {showRejectionSection && <Box>
                        <Button variant="contained" color="primary" sx={{ mr: 1, borderRadius: 2 }} onClick={() => ResubmitApplication(myapplicationDetail?._id)}>
                            Resubmit Request
                        </Button>
                        {/* <Button variant="contained" color="success" sx={{ borderRadius: 1 }} >Approve</Button> */}
                    </Box>}
                    {showUserManual && <Box>
                        <Button variant="contained" color="primary" sx={{ mr: 1, borderRadius: 2, whiteSpace: 'nowrap' }} onClick={handleClickUserManual}>
                        User Manual
                        </Button>
                    </Box>}
                </Box>
                <Typography fontFamily={"Inter, sans-serif"} variant="h3" fontWeight={"600"} gutterBottom sx={{ mt: 2, mb: 4 }}>
                    {/* chitraanuvaad */}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography fontFamily={"Inter, sans-serif"} mb={3} variant="subtitle2">
                            <span style={{ display: "inline-block", width: "40%" }}>Application Name</span>
                            <strong>{myapplicationDetail?.application_name}</strong>
                        </Typography>
                        <Typography fontFamily={"Inter, sans-serif"} mb={3} variant="subtitle2">
                            <span style={{ display: "inline-block", width: "40%" }}>Sent Request Date</span>
                            <strong>{dateWithTimezone}</strong>
                        </Typography>
                        <Typography fontFamily={"Inter, sans-serif"} mb={3} variant="subtitle2">
                            <span style={{ display: "inline-block", width: "40%" }}>Request Time</span>
                            <strong>{timeOnly}</strong>
                        </Typography>
                    </Grid>

                    {/* CEO Approval Section */}
                   {(myapplicationDetail?.ceo_approval?.status === 'Pending' && myapplicationDetail?.manager_approval?.status === 'Pending') ? null : <Grid item xs={12} sm={6}>
                        <Typography fontFamily={"Inter, sans-serif"} mb={3} variant="subtitle2">
                            <span style={{ display: "inline-block", width: "40%" }}>{ceoLabels.dateLabel || managerLabels.dateLabel}</span>
                            <strong>{ceoApprovalInfo.dateWithTimezone || managerApprovalInfo.dateWithTimezone}</strong>
                        </Typography>
                        <Typography fontFamily={"Inter, sans-serif"} mb={3} variant="subtitle2">
                            <span style={{ display: "inline-block", width: "40%" }}>{ceoLabels.timeLabel || managerLabels.timeLabel}</span>
                            <strong>{ceoApprovalInfo.timeOnly || managerApprovalInfo.timeOnly}</strong>
                        </Typography>
                        <Typography fontFamily={"Inter, sans-serif"} mb={3} variant="subtitle2">
                            <span style={{ display: "inline-block", width: "40%" }}>Updated By</span>
                            <strong>{myapplicationDetail?.ceo_approval?.updater_name || myapplicationDetail?.manager_approval?.updater_name}({myapplicationDetail?.ceo_approval?.updater_name !== null ? 'CEO' : "Manager"})</strong>
                        </Typography>
                    </Grid>}

                    {/* Manager Approval Section
                    <Grid item xs={12} sm={6}>
                        <Typography fontFamily={"Inter, sans-serif"} mb={3} variant="subtitle2">
                            <span style={{ display: "inline-block", width: "40%" }}>{managerLabels.dateLabel}</span>
                            <strong>{managerApprovalInfo.date}</strong>
                        </Typography>
                        <Typography fontFamily={"Inter, sans-serif"} mb={3} variant="subtitle2">
                            <span style={{ display: "inline-block", width: "40%" }}>{managerLabels.timeLabel}</span>
                            <strong>{managerApprovalInfo.time}</strong>
                        </Typography>
                        <Typography fontFamily={"Inter, sans-serif"} mb={3} variant="subtitle2">
                            <span style={{ display: "inline-block", width: "40%" }}>Updated By (Manager)</span>
                            <strong>{myapplicationDetail?.manager_approval?.updater_name || 'N/A'}</strong>
                        </Typography>
                    </Grid> */}
                </Grid>

                {showRejectionSection && (
                    <>
                        <Divider sx={{ margin: "3rem 0rem" }} />

                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={12} md={2.4}>
                                <Typography fontFamily={"Inter, sans-serif"} mb={3} variant="subtitle2"><span>Reason for Rejection</span></Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={9.6}>
                                <Typography fontFamily={"Inter, sans-serif"} mb={2} variant="subtitle2">
                                    <strong>{myapplicationDetail?.ceo_approval?.remarks_title || myapplicationDetail?.manager_approval?.remarks_title}</strong>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={2.4}>
                                <Typography fontFamily={"Inter, sans-serif"} mb={3} variant="subtitle2"  style={{ whiteSpace: 'normal', overflowWrap: 'break-word',wordWrap: 'break-word',hyphens: 'auto'}}><span>Description</span></Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={9.6}>
                                <Typography fontFamily={"Inter, sans-serif"} mb={2} variant="subtitle2">
                                    <strong>{myapplicationDetail?.ceo_approval?.remarks_description || myapplicationDetail?.manager_approval?.remarks_description}</strong>
                                </Typography>
                            </Grid>
                        </Grid>
                    </>
                )}
                 <Divider />
                  <Grid container sx={{mt:5,mb:5}}>
                    <Grid item xs={6} sm={6} md={2.4} lg={2.4} xl={2.4}>
                      <Typography fontFamily={"Inter, sans-serif"} variant="subtitle2">
                      CEO’s Remark
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={9.6} lg={9.6} xl={9.6}>
                      <Typography  fontWeight={400}  variant="subtitle2">
                      <strong> {myapplicationDetail?.ceo_approval?.remarks_description}</strong>
                      </Typography>
                    </Grid></Grid>
                {/* && myapplicationDetail?.status === 'Approved' */}
      {myapplicationDetail?.application_name === 'Bhashini Translation Plugin' && myapplicationDetail?.status === 'Approved'  ? 
      <>
        <Divider />
            <ViewWTMSDetails  UlcaApiKeys={UlcaApiKeys} myapplicationDetail={myapplicationDetail}/> 
      </>: ''}

            </Paper>
        </Container>
    );
}

export default ViewApplicationDetails;
