
import API from "../../api";
import ENDPOINTS from "../../../../../config/apiEndpoint";
import C from "../../../../constants";

export default class RevertdTOManagerAPI extends API {
  constructor(id,payload,timeout = 2000) {
    super("POST", timeout, false);
    this.type = C.REVERT_TO_MANAGER;
    this.payload = payload;
    this.endpoint = `${super.apiEndPointAuto()}${ENDPOINTS.revert_to_manager}?request_id=${id}`;
  }

  processResponse(res) {
    super.processResponse(res);
    if (res) {
        this.report = res;
    }
}

  apiEndPoint() {
    return this.endpoint;
  }

  getBody() {
    return {
      ...this.payload
    };
  } 

  getHeaders() {
    const authorization = localStorage.getItem("token");
    this.headers = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": authorization
      },
    };
    return this.headers;
  }

  getPayload() {
    return this.report
  }
}
