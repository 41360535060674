// LogoutAPI.js
import API from "../../api";
import ENDPOINTS from "../../../../../config/apiEndpoint";
import C from "../../../../constants";

export default class FetchNotificationsAPI extends API {
  constructor( payload, timeout = 2000) {
    super("POST", timeout, false); 
    this.type = C.FETCH_NOTIFICATIONS_LIST;
    this.payload = payload;
    this.endpoint = `${super.apiEndPointAuto()}${ENDPOINTS.list_notifications}?page=1&page_size=2000`;
  }

  processResponse(res) {
    super.processResponse(res);
    if (res) {
        this.report = res;
    }
  }

  apiEndPoint() {
    return this.endpoint;
  }

  getBody() {
    
    return {
      ...this.payload
    };
  }  

  getHeaders() {
    return {
      headers: {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('token')
      },
    };
  }

  getPayload() {
    return this.report;
  }
}
