
import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";

const steps = [
  { label: "Request Raised" },
  { label: "Manager Approval" },
  { label: "CEO Approval" },
];

const CustomStepIcon = (props) => {
  const { icon, myapplicationDetail, label } = props;
  let bgColor = "#F0B727"; 

  if (label === "CEO Approval") {
    bgColor = myapplicationDetail?.ceo_approval?.status === "Rejected"
      ? "#E94949"
      : myapplicationDetail?.ceo_approval?.status === "Approved"
      ? "#05B966"
      : bgColor;
  } else if (label === "Manager Approval") {
    bgColor = myapplicationDetail?.manager_approval?.status === "Rejected"
      ? "#E94949"
      : myapplicationDetail?.manager_approval?.status === "Approved"
      ? "#05B966"
      : bgColor;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 25,
        height: 25,
        borderRadius: "50%",
        bgcolor: bgColor,
        color: "white",
        fontSize: "14px",
      }}
    >
      {icon}
    </Box>
  );
};

const CustomStepLabel = styled(StepLabel)(({ theme,showRejectionSection }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
  "& .custom-text": {
    color: "black",
    fontSize:showRejectionSection?"13px": "14px",
    position: "absolute",
    bottom:showRejectionSection ? 30:25,
    right:showRejectionSection ?-30:-10,
  },
}));

function RequestdetailsStepper({ myapplicationDetail, showRejectionSection }) {
  return (
    <Box sx={{ width: showRejectionSection ? "50%" : "100%" , }}>
      <Grid container >
        <Grid item xs={12}>
        <Stepper
        activeStep={1}
        alternativeLabel
        myapplicationDetail={myapplicationDetail}
      >
        {steps.map(({ label }, index) => (
          <Step key={label} completed={index <= 1}>
            <CustomStepLabel showRejectionSection={showRejectionSection} StepIconComponent={(props) => <CustomStepIcon {...props} icon={index + 1} myapplicationDetail={myapplicationDetail} label={label} />}>
            {index === 0 && (
                <Typography variant="caption" className="custom-text" >
                   {myapplicationDetail?.ceo_approval_tat} Days
                </Typography>
              )}
              {index === 1 && (
                <Typography variant="caption" className="custom-text" >
                 
                 {myapplicationDetail?.ceo_approval_tat} Days

                </Typography>
              )}
              {label}
            </CustomStepLabel>
          </Step>
        ))}
      </Stepper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default RequestdetailsStepper;


